import MagnifyIcon from "mdi-react/MagnifyIcon";
import EarthIcon from "mdi-react/EarthIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import CrownIcon from "mdi-react/CrownIcon";
import { useEffect } from "react";
import { useState } from "react";
import PaystackPop from "@paystack/inline-js";
import { Form, Card, Button, Nav, Dropdown, Table, Alert, Modal } from "react-bootstrap";
import {
  NavLink,
  Outlet,
  Link,
  useInRouterContext,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
import NewRequisitionFormBuilder from "./NewRequisitionFormBuilder";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, parse } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import {
  DeleteIcon,
  DropdownEyeIcon,
  EditIcon,
  LinkVariantIcon,
  RecieptIcon, FromInPreviewIcon,
  FormGridIcon,
  SearchIconV2,
  RefreshIcon
} from "../Icons";
import NewCustomRequisitionModal from "./NewCustomRequisitionModal";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";
import EditRequisitionFormBuilder from "./EditRequisitionFormBuilder";
import NewFillableFormModal from "./NewFillableFormModal";
import CachedIcon from "mdi-react/CachedIcon";
import ViewGridIcon from "mdi-react/ViewGridIcon";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon";
import { copyText, paginationOptions, slugify, styleHelper } from "../../utils/helpers";
import FilledForms from "./FilledForms";
import { services } from "../../config";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import ApprovalBadge from "../utils/ApprovalBadge";
import FormPreviewModal from "./FormPreviewModal";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import { nanoid } from "nanoid";
import { useStoreState } from "easy-peasy";

export default function Forms({ formType = "Public" }) {
  const initialFilterParams = {
    q: "",
    formType,
  };

  const queryClient = useQueryClient();
  const { backendUrl, user: authUser } = useAuth();
  const [selectedForm, setSelectedForm] = useState();
  const [showFormPreviewModal, setShowFormPreviewModal] = useState();
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [showEditRequisitionModal, setShowEditRequisitionModal] = useState(
    false
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showFilledFormsModal, setShowFilledFormsModal] = useState(false);
  const [viewType, setViewType] = useLocalStorage("grid");
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 42,
    formType,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchForms = async (queryParams) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/forms?&${queryString.stringify({
        ...queryParams,
        formType,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      count: 0,
      forms: [],
    },
    refetch,
    isFetched,
  } = useQuery(
    ["SYSTEM_FORMS", formType, queryParams],
    () => fetchForms(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const enableForm = ({ id, enabled }) => {
    enableMutation.mutate(
      {
        id,
        enabled,
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            ["SYSTEM_FORMS", formType, queryParams],
            (data) => {
              data.forms.map((el) => {
                if (el.id === id) {
                  el.enabled = enabled;
                }
                return el;
              });
              return data;
            }
          );
        },
      }
    );
  };

  const deleteFormApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/delete-form`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteMutation = useMutation((payload) => deleteFormApi(payload), {
    onSuccess: () => {
      toast.success(`Form Deleted`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const enableFormApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/enable-form`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const enableMutation = useMutation((payload) => enableFormApi(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const deleteForm = async (el) => {
    if (
      await ConfirmDialog({
        type: 'danger',
        title: "Delete",
        description: "Are you sure, you want to delete this form",
      })
    ) {
      deleteMutation.mutate({
        id: el.id,
      });
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };
  /*
  const compileFormApi = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/forms/sync-form/${payload.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const compileMutation = useMutation((payload) => compileFormApi(payload), {
    onSuccess: ({ compiledForm }) => {
      setSelectedForm(compiledForm);
      setShowFormPreviewModal(true);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const compileRegister = (form) => {
    compileMutation.mutate(form);
  }; */

  const handleNewForm = () => {
    if (authUser?.maxFormsAllowed && Number(authUser?.maxFormsAllowed) <= Number(data?.formsUsed)) {
      return toast.info('Limit Reached');
    }
    return setShowNewRequisitionModal(true);
  }

  return (
    <>
      <main className="cash-book">
        <div className="content">

          <header>
            <h1>Forms </h1>

            <div className="d-flex gap-3">
              {authUser?.maxFormsAllowed == 3 && <Button variant="" size="sm" onClick={() => setShowPaymentModal(true)}><CrownIcon /> Upgrade to Prenium</Button>}

              {formType !== "Public" && (
                <div className="d-flex  gap-4 border  align-items-center  btn">
                  <div>
                    <span className="fs-4">{authUser?.maxFormsAllowed ? authUser?.maxFormsAllowed : '∞'}</span>
                    {"   "}
                    <span>Available</span>
                  </div>
                  <div>
                    <span className="fs-4 ">{data?.formsUsed || "..."}</span>
                    {"   "}
                    <span>Used</span>
                  </div>
                  {/* <div>
                    <span className="fs-4">{currency(Infinity).subtract(data?.formsUsed).value}</span>
                    {"   "}
                    <span>Remaining</span>
                  </div> */}


                </div>
              )}

              <Button
                variant=""
                className={`border form-view-btn ${viewType === "grid" || !viewType ? "bg-light-blue" : ""
                  }`}
                onClick={() => setViewType("grid")}
              >
                <FormGridIcon />
              </Button>{" "}
              <Button
                variant=""
                className={`border form-view-btn ${viewType === "list" ? "bg-light-blue" : ""
                  }`}
                onClick={() => setViewType("list")}
              >
                <FormatListBulletedIcon />
              </Button>
              <button
                onClick={() => handleNewForm()}
                className="btn btn-primary new"
              >
                + New Form
              </button>
            </div>
          </header>

          <div className="dashboard-tabs innerpage-tabs px-4" style={{ height: 'fit-content' }}>
            <ul className={`nav nav-tabs ${styleHelper.isMobile ? 'nav-fill' : ''}`}>
              <Nav fill={styleHelper.isMobile}>
                <NavLink
                  to="/requisition/forms/templates"
                  className={`nav-link ga-nav-tab`}
                >
                  Templates
                </NavLink>
                <NavLink
                  to="/requisition/forms/company"
                  className={`nav-link ga-nav-tab`}
                >
                  {authUser.account_type === 1 ? 'Personal' : 'Company'}
                </NavLink>
                <NavLink
                  to="/requisition/forms/public"
                  className={`nav-link ga-nav-tab`}
                >
                  Public
                </NavLink>
                <NavLink
                  to="/requisition/forms/system"
                  className={`nav-link ga-nav-tab`}
                >
                  System Forms
                </NavLink>
                {/* <NavLink
                to="/requisition/forms/draft"
                className={`nav-link ga-nav-tab`}
              >
                Draft
              </NavLink> */}
              </Nav>
            </ul>
          </div>

          <div className="requisition-control-area align-items-center">
            <div className="search-area">
              <SearchIconV2 />
              <Form.Control
                id="queryParams-q"
                className=""
                name="q"
                value={filterParams.q}
                onChange={(e) => handleFilterParamsChange(e)}
                placeholder="Search Title..."
              />
            </div>

            {/*  <Form.Select
          name="Type"
          value={filterParams.Type}
          onChange={(e) =>  handleFilterParamsChange(e)}
        >
          <option value="">Sort by Type</option>
          {data.formTypes.map((el) => (
            <option>{el.type}</option>
          ))}
        </Form.Select> */}

            <Form.Switch
              inline
              id="Draft"
              label="Draft"
              name="Draft"
              type={"checkbox"}
              checked={filterParams.Draft}
              onChange={(e) => handleFilterParamsChange(e)}
            />

            {/*   <Form.Select
          name="status"
          value={queryParams.status}
          onChange={(e) =>  handleFilterParamsChange(e)}
        >
          <option value="">Sort by Status</option>
          <option>Pending</option>
          <option>Approved & Sent</option>
          <option>Approved & Closed</option>
          <option>Disapprove</option>
        </Form.Select> */}

            <Button
              onClick={() => refetch()}
              variant="outline-primary"
              className="border-0"
              title="Refresh"
            >
              <RefreshIcon />
            </Button>
          </div>

          <div className="p-4 pt-0">
            {viewType === "grid" || !viewType ? (
              <div className="row gx-4 px-0">
                {data.forms.map((el, i) => (
                  <div key={i} className="col-md-6 mb-4 ">
                    <Card className="h-100">
                      <Card.Header className="bg-white border-bottom p-3 pb-0 d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
                          <div className="flex-grow-1 align-items-center">
                            {el.enabled ? (
                              <ApprovalBadge
                                text={"Live"}
                                className="approval"
                                bg="success"
                              />
                            ) : (
                              <ApprovalBadge
                                text={"Offline"}
                                className="approval"
                                bg="orange-warning"
                              />
                            )}
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="fw-medium">
                              Created :{" "}
                              {el?.Date_Log
                                ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                                : "..."}
                            </span>
                            <Dropdown style={{ margin: 0 }} alignRight
                              drop="end">
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border border-white"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setSelectedForm(el);
                                    setShowFormPreviewModal(true);
                                  }}
                                >
                                  <DropdownEyeIcon /> Preview
                                </Dropdown.Item>
                                {/*   <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  compileRegister(el);
                                }}
                              >
                                <DropdownEyeIcon /> Compile Register
                              </Dropdown.Item> */}
                                <Dropdown.Item
                                  as="button"
                                  onClick={() =>
                                    copyText(
                                      formType === "Public"
                                        ? `${services.frontendUrl}/public-form/${el.id
                                        }/${authUser.company}/${slugify(
                                          el.Title
                                        )}`
                                        : `${services.frontendUrl}/company-form/${el.id
                                        }/${authUser.company}/${slugify(
                                          el.Title
                                        )}`
                                    )
                                  }
                                >
                                  <LinkVariantIcon />
                                  Copy Link
                                </Dropdown.Item>
                                {(el.approvalType === "Not-Required" ||
                                  el.approvalType ===
                                  "Fill and Approval Required") &&
                                  el.enabled ? (
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={
                                        formType === "Public"
                                          ? `/public-form/${el.id}/${authUser.company
                                          }/${slugify(el.Title)}`
                                          : `/company-form/${el.id}/${authUser.company
                                          }/${slugify(el.Title)}`
                                      }
                                    >
                                      <RecieptIcon /> Fill Form
                                    </Link>
                                  </Dropdown.Item>
                                ) : null}

                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setSelectedForm(el);
                                    setShowFilledFormsModal(true);
                                  }}
                                >
                                  <DropdownEyeIcon /> Filled Forms
                                </Dropdown.Item>

                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setSelectedForm(el);
                                    setShowEditRequisitionModal(true);
                                  }}
                                >
                                  <EditIcon /> Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                  onClick={() => deleteForm(el)}
                                >
                                  <DeleteIcon /> Delete
                                </Dropdown.Item>

                                <div className="p-3">
                                  <Form.Switch
                                    label="Enabled "
                                    inline
                                    type={"checkbox"}
                                    checked={el.enabled}
                                    onChange={(e) => {
                                      enableForm({
                                        id: el.id,
                                        enabled: e.target.checked,
                                      });
                                    }}
                                  />
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-3">
                        <div className="d-flex justify-content-between p-cursor"
                          onClick={() => {
                            setSelectedForm(el);
                            setShowFormPreviewModal(true);
                          }}>
                          <div>
                            <Card.Subtitle className="mb-2 text-muted fw-normal">
                              {el.documentNumber || ""}{" "}
                              {el.formNumberPrefix || "..."} {el.Category || "..."}
                            </Card.Subtitle>

                            <Card.Title>
                              <span className="fw-bold">
                                {el.Title || "..."}</span>
                            </Card.Title>
                            <Card.Text className="text-light">Revision: {el.revision || '...'}</Card.Text>
                          </div>

                          <div>
                            <FromInPreviewIcon />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <Table
                borderless
                responsive
                hover
                striped
                className="product-table"
              >
                <thead>
                  <tr>
                    <th />
                    <th>Document Number</th>
                    <th>Form Number</th> <th>Title</th> <th>Category</th>
                    <th>Approval</th> <th>Revision</th> <th>Date Log</th>
                    <th>Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  {data.forms.map((el, index) => (
                    <tr className="p-cursor" key={index}>
                      <td>
                        <Dropdown style={{ margin: 0 }}>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border border-white"
                            bsPrefix="print more"
                          >
                            <DotsHorizontalIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowFormPreviewModal(true);
                              }}
                            >
                              <DropdownEyeIcon /> Preview
                            </Dropdown.Item>

                            {/*  <Dropdown.Item
                              as="button"
                              onClick={() => {
                                compileRegister(el);
                              }}
                            >
                              <DropdownEyeIcon /> Compile Register
                            </Dropdown.Item> */}
                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                copyText(
                                  formType === "Public"
                                    ? `${services.frontendUrl}/public-form/${el.id
                                    }/${authUser.company}/${slugify(
                                      el.Title
                                    )}`
                                    : `${services.frontendUrl}/company-form/${el.id
                                    }/${authUser.company}/${slugify(
                                      el.Title
                                    )}`
                                )
                              }
                            >
                              <LinkVariantIcon />
                              Copy Link
                            </Dropdown.Item>
                            {(el.approvalType === "Not-Required" ||
                              el.approvalType ===
                              "Fill and Approval Required") &&
                              el.enabled ? (
                              <Dropdown.Item as="div">
                                <Link
                                  to={
                                    formType === "Public"
                                      ? `/public-form/${el.id}/${authUser.company
                                      }/${slugify(el.Title)}`
                                      : `/company-form/${el.id}/${authUser.company
                                      }/${slugify(el.Title)}`
                                  }
                                >
                                  <RecieptIcon /> Fill Form
                                </Link>
                              </Dropdown.Item>
                            ) : null}

                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowFilledFormsModal(true);
                              }}
                            >
                              <DropdownEyeIcon /> Filled Forms
                            </Dropdown.Item>

                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setSelectedForm(el);
                                setShowEditRequisitionModal(true);
                              }}
                            >
                              <EditIcon /> Edit
                            </Dropdown.Item>

                            <Dropdown.Item
                              as="button"
                              className="text-danger"
                              onClick={() => deleteForm(el)}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>

                            <div className="p-3">
                              <Form.Switch
                                label="Enabled "
                                inline
                                type={"checkbox"}
                                checked={el.enabled}
                                onChange={(e) => {
                                  enableForm({
                                    id: el.id,
                                    enabled: e.target.checked,
                                  });
                                }}
                              />
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>{el.documentNumber || "..."}</td>
                      <td>{el.formNumberPrefix || "..."}</td>
                      <td>
                        <b>{el.Title}</b>
                      </td>
                      <td>{el.Category || "..."}</td>
                      <td>{el.approvalType || "..."}</td>
                      <td>{el.revision}</td>
                      <td>
                        {el?.Date_Log
                          ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                          : "..."}
                      </td>
                      <td>
                        {el.enabled ? (
                          <ApprovalBadge
                            text={"Live"}
                            className="approval"
                            bg="success"
                          />
                        ) : (
                          <ApprovalBadge
                            text={"Offline"}
                            className="approval"
                            bg="orange-warning"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {isFetched && isEmpty(data.forms) ? (
              <NoTableItem queryParams={queryParams} />
            ) : null}
            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleFilterParamsChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="42">42 rows</option>
                  <option value="100">100 rows</option>
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={data.count / queryParams.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>{" "}
          </div>
        </div >
      </main >

      {showNewRequisitionModal && (
        <NewRequisitionFormBuilder
          showNewRequisitionModal={showNewRequisitionModal}
          setShowNewRequisitionModal={setShowNewRequisitionModal}
          refetch={refetch}
          type={"Form"}
        />
      )
      }

      {
        selectedForm && showEditRequisitionModal ? (
          <EditRequisitionFormBuilder
            showEditRequisitionModal={showEditRequisitionModal}
            setShowEditRequisitionModal={setShowEditRequisitionModal}
            refetch={refetch}
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
            type={"Form"}
          />
        ) : null
      }

      {
        selectedForm && showFilledFormsModal ? (
          <FilledForms
            form={selectedForm}
            setSelectedForm={setSelectedForm}
            setShowFilledFormsModal={setShowFilledFormsModal}
          />
        ) : null
      }

      {
        showFormPreviewModal && selectedForm ? (
          <FormPreviewModal
            showFormPreviewModal={showFormPreviewModal}
            setShowFormPreviewModal={setShowFormPreviewModal}
            template={selectedForm.Template}
            selectedFilledForm={selectedForm}
          />
        ) : null
      }

      {showPaymentModal && <PaymentModal show={showPaymentModal} setShow={setShowPaymentModal} />}

      <ModalLoader
        show={
          deleteMutation.isLoading ||
          enableMutation.isLoading /* ||
          compileMutation.isLoading */
        }
      />
    </>
  );
}


function PaymentModal({
  setShow, show
}) {
  const { backendUrl, user: authUser } = useAuth()
  const [paymentOption, setPaymentOption] = useState('annual');
  const [price, setPrice] = useState(10); // Default monthly price

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const generalSettings = useStoreState((state) => state.generalSettings);


  const paystackConfig = {
    //  reference: new Date().getTime().toString(),
    //  email: "user@example.com",
    // amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    key: process.env.REACT_APP_BILLING_AND_PAYMENT_PAYSTACK_PUBLIC_KEY,
  };

  const pay = async ({ amount, reference, email, companyUrl }) => {
    try {
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,
        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          type: "form-billing",
          companyUrl: companyUrl,
        },
        // currency: "USD",
        // other params

        onSuccess: async (transaction) => {
          navigate(
            `/process-payment-transaction?reference=${reference}`
          );
        },
        onCancel: async () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };

  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    setPaymentOption(selectedOption);

    // Set price based on the selected option
    if (selectedOption === 'monthly') {
      setPrice(10); // Monthly price
    } else if (selectedOption === 'annual') {
      setPrice(100); // Annual price (e.g., discounted)
    }
  };

  const handleSubmit = () => {
    // alert(`You have selected the ${paymentOption} payment option. Total amount: $${price}`);
    // handleClose();
    pay({
      amount: price, reference: nanoid(), companyUrl: backendUrl, email: authUser.email
    })
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Choose Payment Option
      </Button>

      <Modal show={show} onHide={handleClose} centered animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className='h5'>Choose your payment plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              {/*  <Form.Label>Choose your payment plan</Form.Label> */}
              <div className="d-flex flex-column gap-2">
                <Form.Check
                  type="radio"
                  id="monthly"
                  label={`Monthly - ${"NGN"}10/month`}
                  name="paymentOption"
                  value="monthly"
                  checked={paymentOption === 'monthly'}
                  onChange={handleOptionChange}
                />
                <Form.Check
                  type="radio"
                  id="annual"
                  label={`Annual - ${"NGN"}100/year`}
                  name="paymentOption"
                  value="annual"
                  checked={paymentOption === 'annual'}
                  onChange={handleOptionChange}
                />
              </div>
            </Form.Group>

            <div style={{ maxWidth: "8rem" }} className="my-4"><img src="/card.png" alt="source" class=" img-fluid" /></div>

            {/* Show the amount they will pay */}
            {/*  <Alert variant="info">
              You will be charged ${price} {paymentOption === 'monthly' ? 'per month' : 'per year'}.
            </Alert> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Confirm Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

