import React, { useMemo } from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import ExpensesSelector from "../utils/ExpensesSelector";
import "../../assets/scss/create-invoice.scss";
import "../../assets/scss/item-select-modal.scss";
import { useCurrencies, useEffectOnce } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { useAuth } from "../../hooks/useAuth";
import { EditableAccountRowModal } from "./EditableAccountRowModal";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import {
  CashSelectIcon,
  ChequeSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
} from "../Icons";
import { fetchActionsUtil } from "../../utils/helpers";
import ModalLoader from "../utils/ModalLoader";
import currency from "currency.js";

export const SalaryAccountModal = (props) => {
  const paymentMethod = [
    {
      icon: <CreditSelectIcon />,
      label: "Imprest",
      value: "Imprest",
    },
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },

    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
  ];

  const cashOptions = [
    {
      label: "Cash On Hand",
      value: "Cash on hand",
    },
  ];
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();

  const [tableData, setTableData] = useState([]);

  const [loantableData, setLoanTableData] = useState([]);

  const [activeButton, setActiveButton] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { backendUrl } = useAuth();

  const [showLoanExpensesPopover, setShowLoanExpensesPopover] = useState(false);

  useEffectOnce(() => {
    if (location.state && location.state?.expenses) {
      setTableData(location.state.expenses);
      navigate(location.pathname, { replace: true });
    }
  });

  const postJournalEntry = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/journal/journal-entry`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postJournalEntryMutation = useMutation(
    (payload) => postJournalEntry(payload),
    {
      onSuccess: async ({ data, message }) => {
        toast.success(message);
        props.refetch();
        formik.resetForm();
        setTableData([]);
        setLoanTableData([]);
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Reference: "",
      salesDate: moment(),
      PaymentType: "",
      chequeNumber: "",
      Amount: Number(props.inComingAmount.replace(/,/g, "")),
    },
    validationSchema: yup.object().shape({
      Reference: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (isEmpty(tableData))
        return toast.error(`Please select credit account`);

      if (isEmpty(loantableData))
        return toast.error(`Please select debit account`);

      console.log({
        journals: [...tableData, ...loantableData].map((el) => ({
          ...el,
          ...values,
          Ref: props.selectedTitle || "",
        })),
        refID: props.refID,
        type: props?.isSalary
          ? "salary"
          : props?.isAllowance
          ? "allowance"
          : "",
      });

      // send to pending
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        postJournalEntryMutation.mutate({
          journals: [...tableData, ...loantableData].map((el) => ({
            ...el,
            ...values,
            Ref: props.selectedTitle || "",
          })),
          refID: props.refID,
          type: props?.isSalary
            ? "salary"
            : props?.isAllowance
            ? "allowance"
            : "",
        });
      }
    },
    onReset: () => {
      setTableData([]);
      setLoanTableData([]);
    },
  });

  useEffectOnce(() => {
    formik.setFieldValue("Reference", `STN${Date.now()}`);
  });

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    if (!isEmpty(data?.imprest)) {
    }
    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
  } = useQuery(["queryActions.INVOICE_SETUP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const handleSelectedExpense = (expense) => {
    // balance Table
    expense.Credit = Number(props.inComingAmount.replace(/,/g, ""));
    expense.Debit = 0;
    setTableData(() => [expense]);
  };

  const handleSelectedLoanExpense = (expense) => {
    // balance Table
    expense.Debit = Number(props.inComingAmount.replace(/,/g, ""));
    expense.Credit = 0;
    setLoanTableData(() => [expense]);
    setShowLoanExpensesPopover(false);
  };

  const edit = ({ index, name, value }) => {
    tableData[index][name] = value;
    setTableData([...tableData]);
  };
  const loanedit = ({ index, formValues }) => {
    loantableData[index] = {
      ...loantableData[index],
      ...formValues,
    };
    setLoanTableData([...loantableData]);
  };

  //   const handleRemove = (index) => {
  //     setTableData([...tableData.filter((el, i) => i !== index)]);
  //   };

  const handleLoanRemove = (index) => {
    setLoanTableData([...loantableData.filter((el, i) => i !== index)]);
  };

  const resolveFromOptions = (PaymentType) => {
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
          PaymentType
        )
      ? data.banks.filter((el) => el?.currency === prevailingCurrency)
      : cashOptions;
  };

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    formik.setFieldValue("From", "");
  };

  const getFullAccount = async (account) => {
    try {
      setActiveButton(true);
      const { data } = await fetchActionsUtil(
        `${backendUrl}/api/journal?Description=${account}&Type=CASH AND CASH EQUIVALENT`
      );

      const filteredData = data?.journal?.find(
        (el) => el.Description === account
      );

      handleSelectedExpense(filteredData);

      setActiveButton(false);
    } catch (error) {
      setActiveButton(true);
      console.log(error);
    }
  };

  const filterImprest = (account) => {
    const filteredData = data?.imprest?.find(
      (el) => el.Description === account
    );

    handleSelectedExpense(filteredData);
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      dialogClassName="loan-account-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Account</h1>
          <div className="h5">
            {props?.isSalary ? <p>Pay Salary.</p> : <p>Pay Allowance.</p>}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <section className="item-details">
              <div>
                <div className="selected-data-area">
                  <div className="table-holder">
                    <p className="fw-bold fs-5">Credit to account </p>

                    <Row className="form-mb mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>By</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPlacement="top"
                          placeholder="Choose method"
                          isSearchable={false}
                          key={formik.values?.PaymentType}
                          value={paymentMethod.find(
                            (el) => el.value === formik.values.PaymentType
                          )}
                          options={paymentMethod}
                          onChange={({ value }) => handlePaymentMethod(value)}
                          getOptionLabel={(el) => (
                            <div className="label-with-icon d-flex gap-2 align-items-center">
                              <span>{el.icon}</span>{" "}
                              <span className="fw-5">{el.label}</span>
                            </div>
                          )}
                          //  menuIsOpen={true}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>From</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          value={resolveFromOptions(
                            formik.values.PaymentType
                          ).find((el) => el.value === formik.values.From)}
                          options={resolveFromOptions(
                            formik.values.PaymentType
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("From", value);

                            if (
                              [
                                "Cheque",
                                "Direct Bank Transfer",
                                "Credit/Debit Card",
                                "Cash",
                              ].includes(formik.values.PaymentType)
                            ) {
                              getFullAccount(value);
                            } else {
                              filterImprest(value);
                            }
                          }}
                          key={formik.values?.PaymentType}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group className="form-mb mb-3">
                      <CurrencyCustomInput
                        currencySymbol={currencySymbol}
                        name={`Amount`}
                        value={formik.values.Amount}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>

                    {formik.values.PaymentType === "Cheque" && (
                      <Form.Group className="form-mb align-items-center">
                        <Form.Label className="mb-1 text-nowrap fw-bold">
                          Cheque No.
                        </Form.Label>
                        <Form.Control
                          name="chequeNumber"
                          value={formik.values.chequeNumber}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "chequeNumber",
                              e.target.value
                            );
                            edit({
                              index: 0,
                              value: e.target.value,
                              name: "Remark",
                            });
                          }}
                        />
                      </Form.Group>
                    )}
                  </div>

                  <div className="my-4" />

                  <div className="table-holder">
                    <p className="fw-bold fs-5">Debit to account</p>
                    <Table
                      responsive
                      borderless
                      className="product-table text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>GL/Accounts</th>
                          <th>Account Type</th>
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody key={loantableData?.length}>
                        {loantableData.map((el, index) => (
                          <React.Fragment key={index}>
                            <EditableAccountRowModal
                              index={index}
                              expense={el}
                              handleRemove={handleLoanRemove}
                              edit={loanedit}
                              accountType={"ACCOUNTS PAYABLE"}
                            />
                          </React.Fragment>
                        ))}
                        {loantableData.length < 1 && (
                          <tr style={{ backgroundColor: "#F7f7f7" }}>
                            <td colSpan={7}>
                              <Popover
                                isOpen={showLoanExpensesPopover}
                                onClickOutside={() =>
                                  setShowLoanExpensesPopover(false)
                                }
                                content={() => (
                                  <ExpensesSelector
                                    handleSelectedExpense={
                                      handleSelectedLoanExpense
                                    }
                                    Type={"ACCOUNTS PAYABLE"}
                                  />
                                )}
                                position="bottom"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="px-3"
                                  onClick={() =>
                                    setShowLoanExpensesPopover(true)
                                  }
                                >
                                  + Add
                                </Button>
                              </Popover>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="d-flex justify-content-end my-4 mx-4">
                    <button
                      onClick={formik.submitForm}
                      className="btn btn-primary"
                      disabled={activeButton}
                    >
                      Send Account
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <ModalLoader show={postJournalEntryMutation.isLoading || isLoading} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
