import React from "react";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

import ExpensesSelector from "../utils/ExpensesSelector";
import "../../assets/scss/create-invoice.scss";
import "../../assets/scss/item-select-modal.scss";
import { useEffectOnce } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { useAuth } from "../../hooks/useAuth";
import { EditableAccountRowModal } from "./EditableAccountRowModal";

export const LoanAccountModal = (props) => {
  const [tableData, setTableData] = useState([]);

  const [loantableData, setLoanTableData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { backendUrl } = useAuth();
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const [showLoanExpensesPopover, setShowLoanExpensesPopover] = useState(false);

  useEffectOnce(() => {
    if (location.state && location.state?.expenses) {
      setTableData(location.state.expenses);
      navigate(location.pathname, { replace: true });
    }
  });

  const postJournalEntry = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/journal/journal-entry`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postJournalEntryMutation = useMutation(
    (payload) => postJournalEntry(payload),
    {
      onSuccess: async ({ data, message }) => {
        toast.success(message);
        props.refetch();
        formik.resetForm();
        setTableData([]);
        setLoanTableData([]);
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Reference: "",
      salesDate: moment(),
    },
    validationSchema: yup.object().shape({
      Reference: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (isEmpty(tableData))
        return toast.error(`Please select credit account`);

      if (isEmpty(loantableData))
        return toast.error(`Please select debit account`);

      // send to pending
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        postJournalEntryMutation.mutate({
          journals: [...tableData, ...loantableData].map((el) => ({
            ...el,
            ...values,
            Ref: props?.isDeduction
              ? props?.isDeduction
              : props?.isLoan
              ? "Loan Payment"
              : "",
          })),
          refID: props.refID,
          type: props?.isDeduction ? "deduction" : props?.isLoan ? "loan" : "",
        });
      }
    },
    onReset: () => {
      setTableData([]);
      setLoanTableData([]);
    },
  });

  useEffectOnce(() => {
    formik.setFieldValue("Reference", `STN${Date.now()}`);
  });

  const handleSelectedExpense = (expense) => {
    // balance Table
    expense.Credit = Number(props.loanAmount);
    expense.Debit = 0;
    // console.log(expense);
    setTableData(() => [expense]);
    setShowExpensesPopover(false);
  };

  const handleSelectedLoanExpense = (expense) => {
    // balance Table
    expense.Debit = Number(props.loanAmount);
    expense.Credit = 0;
    // console.log(expense);
    setLoanTableData(() => [expense]);
    setShowLoanExpensesPopover(false);
  };

  const edit = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };
  const loanedit = ({ index, formValues }) => {
    loantableData[index] = {
      ...loantableData[index],
      ...formValues,
    };
    setLoanTableData([...loantableData]);
  };

  const handleRemove = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const handleLoanRemove = (index) => {
    setLoanTableData([...loantableData.filter((el, i) => i !== index)]);
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      dialogClassName="loan-account-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Account</h1>
          <div className="h5">
            {props?.isDeduction ? (
              <p>Pay {props?.isDeduction}.</p>
            ) : (
              <p>Add Account to Loan.</p>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <section className="item-details">
              <div>
                <div className="selected-data-area">
                  <div className="table-holder">
                    <p className="fw-bold fs-5">Credit to account </p>
                    <Table
                      responsive
                      borderless
                      //   hover
                      //   striped
                      className="product-table  text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>GL/Accounts</th>
                          <th>Account Type</th>
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody key={tableData?.length}>
                        {tableData.map((el, index) => (
                          <React.Fragment key={index}>
                            <EditableAccountRowModal
                              index={index}
                              expense={el}
                              handleRemove={handleRemove}
                              edit={edit}
                              accountType={"CASH AND CASH EQUIVALENT"}
                            />
                          </React.Fragment>
                        ))}
                        {tableData.length < 1 && (
                          <tr style={{ backgroundColor: "#F7f7f7" }}>
                            <td colSpan={7}>
                              <Popover
                                isOpen={showExpensesPopover}
                                onClickOutside={() =>
                                  setShowExpensesPopover(false)
                                }
                                content={() => (
                                  <ExpensesSelector
                                    handleSelectedExpense={
                                      handleSelectedExpense
                                    }
                                    // usage={"chart of accounts"}
                                    Type={"CASH AND CASH EQUIVALENT"}
                                  />
                                )}
                                position="bottom"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="px-3"
                                  onClick={() => setShowExpensesPopover(true)}
                                >
                                  + Add
                                </Button>
                              </Popover>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="my-4" />

                  <div className="table-holder">
                    <p className="fw-bold fs-5">Debit to account</p>
                    <Table
                      responsive
                      borderless
                      //   hover
                      //   striped
                      className="product-table text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>GL/Accounts</th>
                          <th>Account Type</th>
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody key={loantableData?.length}>
                        {loantableData.map((el, index) => (
                          <React.Fragment key={index}>
                            <EditableAccountRowModal
                              index={index}
                              expense={el}
                              handleRemove={handleLoanRemove}
                              edit={loanedit}
                              accountType={"ACCOUNTS RECEIVABLE"}
                            />
                          </React.Fragment>
                        ))}
                        {loantableData.length < 1 && (
                          <tr style={{ backgroundColor: "#F7f7f7" }}>
                            <td colSpan={7}>
                              <Popover
                                isOpen={showLoanExpensesPopover}
                                onClickOutside={() =>
                                  setShowLoanExpensesPopover(false)
                                }
                                content={() => (
                                  <ExpensesSelector
                                    handleSelectedExpense={
                                      handleSelectedLoanExpense
                                    }
                                    // usage={"chart of accounts"}
                                    Type={
                                      props?.isDeduction
                                        ? "EXPENSES"
                                        : "ACCOUNTS RECEIVABLE"
                                    }
                                  />
                                )}
                                position="bottom"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="px-3"
                                  onClick={() =>
                                    setShowLoanExpensesPopover(true)
                                  }
                                >
                                  + Add
                                </Button>
                              </Popover>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="d-flex justify-content-end my-4 mx-4">
                    <button
                      onClick={formik.submitForm}
                      className="btn btn-primary"
                    >
                      Send Account
                    </button>
                  </div>

                  {/* {isEmpty(tableData) ? (
                    <div className="no-item my-4">
                      <div className="text-center">
                        <NoSelectedItemIcon />
                        <h6 className="mb-2">Haven't selected an item yet</h6>
                        <p>You can click + Add to add an item to the table.</p>
                      </div>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
