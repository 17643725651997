import { Dropdown, Table, Form, Button } from "react-bootstrap";
import {
  FilterTwoIcon,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MoneyDollarIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  formatDate,
  getUserThatRequestedToMe,
  paginationOptions,
  resolveApprovalBadgeBg,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import ApprovalBadge from "../utils/ApprovalBadge";
import { SalaryAccountModal } from "../modals/SalaryAccountModal";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { LoanAccountModal } from "../modals/LoanAccountModal";

export default function Deductions() {
  useScrollTop();
  const initialFilterParams = {
    q: "",
    status: "Paid",
    type: "salary",
  };

  const { user: authUser, backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [deductionDetails, setDeductionDetails] = useState({
    DAmount: 0,
    DName: "",
    refID: "",
  });

  const [openedRequests, setOpenedRequests] = useState({});

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchForms = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/requisition?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { count: 0, requisitions: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.EXPENSES, queryParams],
    () => fetchForms(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/forms?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.forms.map((row) => ({
      ...row,
      ReqDate: format(new Date(row.ReqDate), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.Header,
      d.ReqName,
      d.Department,
      currency(d.Amount, {
        symbol: "",
      }).format(),
      d.Purpose,
      d.ReqDate,
      d.ExpID,
      d.username,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Forms Report"],
      [date],
      [""],
      [
        "Exp Header",
        "Requester Name",
        "Department",
        "Amount",
        "Purpose",
        "Exp Date",
        "Exp ID",
        "Username",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Forms",
        currency(data?.totalForms, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const totalDeduction = useMemo(() => {
    let count = 0;
    data?.requisitions?.forEach((el) => {
      if (el.status === "Paid") {
        if (JSON.parse(el?.loanDetails)?.deductionItems?.length > 0) {
          count += JSON.parse(el?.loanDetails)?.deductionItems?.reduce(
            (a, b) => a + b?.amount,
            0
          );
        }
      }
    });
    return count;
  }, [data?.requisitions]);

  const payDeduction = ({ DAmount, DName, refID }) => {
    setDeductionDetails({ DAmount, DName, refID });
    setOpenAccountModal(true);
  };

  const getMainStatus = (items) => {
    const messages = {
      "pending payment": "pending payment",
      Paid: "Paid",
      rejected: "rejected",
    };

    let count = 0;
    items?.forEach((el) => {
      if (el?.status) {
        count++;
      }
    });

    const status = items?.length > count ? "pending payment" : "Paid";
    const text = items?.length > count ? "Partial Payment" : "Paid All";

    return {
      text,
      status: messages[status] || "Unknown",
    };
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  {/*   <Form.Group className="mb-2-5">
                    <Form.Label>Branch</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      name="branch"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.branch}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          branch: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.branch,
                        data?.branch,
                        {
                          value: "",
                          label: "All",
                        }
                      )}
                      options={data?.branch || []}
                    />
                  </Form.Group> */}

                  {/*    <hr className="mt-3 mb-4" /> */}

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Deductions
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>
              <div className="actions mr-5">
                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtn "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <Table
                responsive
                borderless
                striped
                hover
                className="product-table"
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Deduction Amount</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data?.requisitions?.map((el, index) => (
                    <Fragment key={index}>
                      <tr
                        key={index}
                        onClick={() =>
                          setOpenedRequests({
                            [el.requestid]: !openedRequests[el.requestid],
                          })
                        }
                        className="p-cursor"
                      >
                        <th>{`${el.title} Deductions`}</th>{" "}
                        <td>
                          <ApprovalBadge
                            text={
                              getMainStatus(
                                JSON.parse(el?.loanDetails)?.deductionItems
                              ).text
                            }
                            className="approval"
                            bg={resolveApprovalBadgeBg(
                              getMainStatus(
                                JSON.parse(el?.loanDetails)?.deductionItems
                              ).status
                            )}
                          />
                        </td>
                        <th>
                          {JSON.parse(el?.loanDetails)?.deductionItems?.length >
                          0
                            ? currency(
                                JSON.parse(
                                  el?.loanDetails
                                )?.deductionItems?.reduce(
                                  (a, b) => a + b?.amount,
                                  0
                                ),
                                {
                                  symbol: !el.currency
                                    ? ""
                                    : el?.currency === "USD"
                                    ? "$"
                                    : "₦",
                                }
                              ).format()
                            : "..."}
                        </th>
                        <td>
                          {openedRequests[el.requestid] ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronUpIcon />
                          )}
                        </td>
                      </tr>

                      {/* sub */}
                      {openedRequests[el.requestid] && (
                        <Fragment>
                          {JSON.parse(el?.loanDetails)?.deductionItems?.map(
                            (d) => (
                              <tr>
                                <td>{d?.name}</td>

                                <td>
                                  {currency(d?.amount, {
                                    symbol: "",
                                  }).format()}
                                </td>
                                <th>
                                  {d?.status ? (
                                    <p className="text-success fs-5 fw-bold mx-3">
                                      Paid
                                    </p>
                                  ) : (
                                    <button
                                      className="btn btn-primary text-white fs-6 px-4"
                                      onClick={() =>
                                        payDeduction({
                                          DAmount: d?.amount,
                                          DName: `${el.title} Deductions (${
                                            d?.name
                                          })`,
                                          refID: el.requestid,
                                        })
                                      }
                                    >
                                      Pay
                                    </button>
                                  )}
                                </th>
                                <td />
                              </tr>
                            )
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.requisitions) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                  <option value="500">500 rows</option>{" "}
                  <option value="1000">1000 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalDeduction, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Deduction (NGN)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLoader show={isfetchingExcel} />

      {openAccountModal ? (
        <LoanAccountModal
          onHide={() => setOpenAccountModal(false)}
          show={openAccountModal}
          refID={deductionDetails.refID}
          refetch={refetch}
          loanAmount={deductionDetails.DAmount}
          isDeduction={deductionDetails.DName}
        />
      ) : null}
    </main>
  );
}
